<template>
    <div class="version-container" v-if="apiData">
        <b>Versions:</b>
        <div>
            Service Desk UI: {{appVersion}}
        </div>
        <!-- API version(s) -->
        <div v-for="(row, index) in apiData" :key="index">
            {{row.name}}: {{row.version}}
        </div>
    </div>
</template>

<script>
export default {
    name: "Version",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION ?? null
    }),
    props: {
        apiData: {type: Array},
    }
};
</script>

<style>
    .version-container {
        padding-top: 1em;
        border-top: .5px solid lightgray;
        font-size: .85em;
    }
</style>
