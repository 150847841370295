<template>
	<div class="d-flex justify-content-center spinner-container">
		<div class="spinner-border purpleText" role="status">
			<span class="visually-hidden-focusable">Loading...</span>
		</div>
	</div>
</template>

<script>

import common from "tc2.common.ui";

export default {
	name: "Callback",
	components: {

	},

	async mounted() {
		console.log(common.userManager);
		if (common.userManager == null || common.userManager == undefined) {
			common.configureUserManager();
		}
		common.handleLoginCallback();
	}
};
</script>

<style scoped lang="scss">
	@import '~tc2.common.ui/dist/index.scss';
</style>
