import Vue from 'vue';
import App from "./pages/App.vue";
import Callback from "./pages/Callback.vue";
import NotFound from "./pages/404.vue";
import ui from "tc2.common.ui";

const i18n = ui.i18n;

const app = new Vue({
	i18n,
  data: {
    currentRoute: window.location.pathname
  },
	render (h) {
    if (this.currentRoute == "/") {
      return h(App);
    } else if (this.currentRoute.includes("/callback")) {
      return h(Callback)
    }
    return h(NotFound)
  }
}).$mount('#app')

window.document.addEventListener("DOMContentLoaded", function() {
  app.currentRoute = window.location.pathname;
  document.title = app.currentRoute.includes("/callback") ? "Loading..." : "Sachet image read errors";
});
