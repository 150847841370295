export default { 
    pageTitle: "Sachet image read errors", 
    notificationTitle: "New active refill errors!",
    localStorageNotificationsEnabled: "refillActiveNotificationsEnabled",
    localStorageNotificationEnabledChangeEvent: "refillActiveNotificationsEnabled-change",
    listNotUpdatedErrorMsg: "List could not be updated",
    lastUpdatedTimeFormat: "HH:mm:ss (DD.MM.YYYY)",
    timeReceivedTimeFormat: "DD.MM.YYYY HH:mm",
    loginAppErrorMsg: "Error: The login application is currently not available\n",
    typeMappings_refill: "Refill error",
    typeMappings_2Or3SachetRead: "2nd or 3rd sachet read error",
    typeMappings_sachetRead: "Sachet read error"
}