<template>
    <div class="dropdown d-inline me-4">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 48 48">
                <path d="M0 0h48v48h-48z" fill="none" />
                <path d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
            </svg>
        </button>
        <ul class="dropdown-menu">
            <li>
                <div class="me-1 form-check form-switch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">RefillActive notifications</label>
                    <input v-model="refillActiveNotificationsEnabled" class="form-check-input" type="checkbox"
                        role="switch" id="flexSwitchCheckDefault">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import constants from '../assets/constants';

export default {
    name: "Settings",
    data: () => ({
        refillActiveNotificationsEnabled: {type: Boolean} = false
    }),
    watch: {
        refillActiveNotificationsEnabled: (newValue) => {
            localStorage.setItem(constants.localStorageNotificationsEnabled, newValue.toString());
            document.dispatchEvent(new CustomEvent(constants.localStorageNotificationEnabledChangeEvent, {
                detail: newValue
            }));
        }
    },
    beforeMount() {
        const notificationsEnabled = localStorage.getItem(constants.localStorageNotificationsEnabled);
        // Notifications are enabled by default, otherwise get settings from localStorage.
        if (notificationsEnabled == null) {
            this.refillActiveNotificationsEnabled = true;
        }
        else {
            this.refillActiveNotificationsEnabled = notificationsEnabled === "true" ? true : false;
        }
    },
}
</script>

<style scoped lang="scss">
    @import '~tc2.common.ui/dist/index.scss';

    .dropdown-toggle {
        fill: white;
        --bs-btn-border-color: none;
        &::after {
            display: none;
        }
        &:hover {
            opacity: 0.5;
        }
    }

    .dropdown-menu {
        transform: translate(60px, 49px) !important;
        width: 300px;
        padding: 15px;
        inset: 0px -65px auto auto !important;
        border: .5px solid $primary-color;
        font-weight: 500;
    }

    .form-check-input {
        float: right !important;
    }

    .form-check {
        padding-left: 0 !important;
    }
</style>