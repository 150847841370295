<template>
	<nav class="evondos-nav-bar mb-5">
		<img class="ms-3 me-auto" src="../assets/logo_white.png" alt="Logo">
		<div v-if="user != null">
			<span class="me-2 btn username-text pointer-default">
				{{ user.given_name }} {{ user.family_name }}
			</span>
			<Settings />
			<a 
				class="me-3 signout-btn btn navbar-btn"
				@click="signout">
				{{$t('Sign out')}}
			</a>
		</div>
	</nav>
</template>

<script>
import Settings from "./Settings.vue"

export default {
	name: "Navbar",
	components: {
		Settings
	},
	props: {
		user: {type: Object}
	},
	methods: {
		signout() {
			this.$emit("signout");
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
	background-color: transparent;
	color: white;
}

.signout-btn {
	border: 1px solid white;
}

.navbar-btn:hover {
	color: white;
	opacity: 0.5;
}

.username-text {
	user-select: none;
	--bs-btn-border-color: none;
}
</style>