<template>
    <div>
        <!-- Loading -->
        <div v-if="loading" class="d-flex justify-content-center spinner-container">
            <div class="spinner-border purpleText" role="status">
                <span class="visually-hidden-focusable">Loading...</span>
            </div>
        </div>
        <div class="table-container">
            <!-- Error messages -->
            <div v-if="!loading && errorMessage && errorMessage.length > 0">
                <pre>
                    <h5 class="text-danger" id="errorMessage">{{$t(errorMessage)}}</h5>
                </pre>
            </div>

            <h5 v-if="forbiddenLocalsList" class="text-warning">
                User does not have access to the following locals: {{forbiddenLocalsList}}
            </h5>

            <!-- No data from API -->
            <div v-if="!loading && errorMessage == '' && pouchReadErrorData.length == 0">
                <h5 id="emptyList">No sachet image read errors found</h5>
            </div>
            <!-- Error data -->
            <table v-if="!loading && pouchReadErrorData.length > 0" class="table table-hover">
                <thead>
                    <tr>
                        <th>{{ $t('View') }}</th>
                        <th>{{ $t('Time left') }}</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('State') }}</th>
                        <th>{{ $t('Dispenser') }}</th>
                        <th>{{ $t('Local') }}</th>
                        <th>{{ $t('Time received') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(error, index) in pouchReadErrorData">
                        <tr :class="[ 'errorRow', error.rowClassName, error.rowClicked === true ? 'disabledRow' : '']" :key="index">
                            <td class="viewButtonTd">
                                <a @click="onViewButtonClick(index)" :href="error.errorDetailsUrl" target="_blank" :id="index">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="viewButton bi bi-arrow-right-circle ms-2" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                    </svg>
                                </a>
                            </td>
                            <td>{{calculateTimeLeftToFix(error.data.lastTimeToStartFixing)}}</td>
                            <td>
                                {{typeMappings[error.data.version] || "Unknown (" + error.data.version + ")"}}
                                <b class="text-danger" v-if="error.couldNotSortItem">
                                    *
                                </b>
                                <small v-if="error.data.isDispenserDemoMode" class="errorTag demoModeTag">
                                    {{ $t('Demo mode') }}
                                </small>
                                <small v-if="error.data.isAssistedMedicineDispensionEnabled" class="errorTag assistedModeTag">
                                    {{ $t('Assisted mode') }}
                                </small>
                                <small v-if="isTravelMode(error.data.travelMode)" class="errorTag travelModeTag">
                                    {{ $t('Travel mode') }}
                                </small>
                                <small v-if="error.data.isPatientDemoMode" class="errorTag demoClientTag">
                                    {{ $t('Demo client') }}
                                </small>
                            </td>
                            <!-- state -->
                            <template>
                                <td v-if="error.data.state && error.data.state.includes('Locked by')">{{$t("Locked by") + (error.data.state.split("Locked by")[1])}}</td>
                                <td v-if="error.data.state != '' && !error.data.state.includes('Locked by ')">{{error.data.state}}</td>
                                <td v-if="error.data.state == ''">-</td>
                            </template>
                            <td>
                                {{error.data.dispenserSerialNumber}}
                            </td>
                            <td>{{error.localServerName}}</td>
                            <td>{{formatTimeReceivedDate(error.data.timeReceived)}}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <Version class="mt-auto my-4 d-block" :apiData="versions" />
        </div>
    </div>
</template>

<script>
import moment from "moment";
import common from "tc2.common.ui";
import constants from "../assets/constants";
import Version from "../components/Version.vue";
import DemoDataAdder from '../services/DemoDataAdder';

export default {
	name: "Table",
	components: {
        Version
    },
	data: () => ({
        sortedData: [],
		pouchReadErrorData: [],
		loading: true,
        errorMessage: "",
        forbiddenLocalsList: "",
        versions: null,
        typeMappings: {
            "R1": constants.typeMappings_refill,
            "": constants.typeMappings_2Or3SachetRead,
            "2": constants.typeMappings_sachetRead
        },
        notifyConfig: {
            notificationInterval: null,
            countOfActiveRefills: 0,
            blinkFreqInMs: 1000,
            refillActiveNotificationsEnabled: localStorage.getItem(constants.localStorageNotificationsEnabled) === "true" ? true : false
        },
        viewButtonDisabledTimeInMs: 15000
	}),
    mounted() {
        // Triggers the tab notification for new RefillActive errors
        document.addEventListener('visibilitychange', (event) => {
            if (this.notifyConfig.refillActiveNotificationsEnabled) {
                if (document.hidden) {
                    this.notifyOnBrowserTab()
                } else {
                    this.cancelNotifyOnBrowserTab();
                }
            }
        });
        
        // Watch changes on the "notifications enabled" value
        document.addEventListener(constants.localStorageNotificationEnabledChangeEvent, (event) => {
            this.notifyConfig.refillActiveNotificationsEnabled = event.detail;
        })
    },
    beforeDestroy() {
        clearInterval(this.notifyConfig.notificationInterval);
    },
	methods: {
        async getPouchReadErrorData(accessToken, isDemo = false) {
            if (isDemo) {
                this.pouchReadErrorData = DemoDataAdder.buildDemoData();
                this.sortData();
                this.loading = false;
            } else {
                return fetch(process.env.VUE_APP_POUCHREADERROR_API_URL, 
                {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                }).then(async response => {
                    if (response != null) {
                        if (response.status == 200) {
                            const data = await response.json();
                            if (data != null) {
                                this.errorMessage = "";
                                this.parseData(data);
                                this.sortData();
                                this.loading = false;
                                return true;
                            }
                        } else if (response.status == 401) {
                            console.warn("No access");
                            this.errorMessage += "401 Unauthorized\n";
                            common.logout();
                        } else {
                            console.warn(response);
                            this.errorMessage += "Internal API error\n";
                        }
                        
                        this.loading = false;
                        return false;
                    }
                }).catch(err => {
                    console.warn(err);
                    this.loading = false;
                    this.errorMessage += `${err.message}\n`;
                    return false;
                });
            }
        },

        parseData(data) {
            // Get the error rows that have been just opened
            const clickedRows = this.pouchReadErrorData
                .map(x => ({ "detailsUrl": x.errorDetailsUrl, "clicked": x.rowClicked }))
                .filter(x => x.clicked === true);

            this.pouchReadErrorData = [];   // Clear the list right before new updated data comes
            this.checkForErrors(data);
            this.checkLocalPermissions(data); // Show a warning message if user has no permissions to view some locals
            this.versions = data.versions;
            // Iterate through the locals
            for (const local of data.result) {
                if (local && local.result && local.result.length > 0) {
                    // Iterate through each read error on each local
                    for (const error of local.result) {
                        this.pouchReadErrorData.push({
                            data: error,
                            localServerName: local.localServerName,
                            errorsExist: local.errorsExist,
                            dispenserSerialNumber: error.dispenserSerialNumber,
                            errorDetailsUrl: error.readErrorDetailsUrl,
                            rowClicked: clickedRows.find(x => x.detailsUrl === error.readErrorDetailsUrl)?.clicked ?? false
                        });
                    }
                }
            }
        },

        sortData() {
            if (this.pouchReadErrorData.length > 0) {
                const categorizedList = this.buildCategorizedList();

                for (const [key, list] of Object.entries(categorizedList)) {
                    this.sortedData.push(...list);
                }
                this.updatePouchReadErrorList();
                this.sortedData = [];

                // Update number of RefillActives (for the notifications, if enabled)
                if (this.notifyConfig.refillActiveNotificationsEnabled) {
                    this.setNumberOfRefillActives();
                }
            }
        },

        // Sort all read errors by "last time to start fixing" by default, most urgent ones first
        // If "last time to start fixing" time is not available, sort by "received", oldest ones first
        sortSubListByDates() {
            const timeLeftZeroStr = "0h 0m";
            return (a, b) => {
                if (a.data != null && b.data != null) {
                    const aTime = a.data.lastTimeToStartFixing;
                    const bTime = b.data.lastTimeToStartFixing;

                    // Both are null or both time left values are 0 => Use time received (never null)
                    if ((aTime == null && bTime == null) || (this.calculateTimeLeftToFix(aTime) == timeLeftZeroStr && this.calculateTimeLeftToFix(bTime) == timeLeftZeroStr)) {
                        return a.data.timeReceived < b.data.timeReceived ? -1 : 1;
                    }
                    // A is null, B is not null
                    else if (aTime == null && bTime != null) {
                        return 1;
                    }
                    // B is null, A is not null
                    else if (bTime == null && aTime != null) {
                        return -1;
                    }
                    // Neither are null
                    else if (aTime != null && bTime != null) {
                        return aTime < bTime ? -1 : 1;
                    }
                }
                return 0;
            }
        },

        // Check if the original and sorted lists have same exact entry ID's, ignoring the ordering
        updatePouchReadErrorList() {
            const idListOfSorted = this.sortedData.map(x => {
                return x.data.id;
            });
            const idListOfOriginal = this.pouchReadErrorData.map(x => {
                return x.data.id;
            });

            const sortingSuccessful = 
                idListOfOriginal.every(x => idListOfSorted.includes(x)) && 
                idListOfSorted.every(x => idListOfOriginal.includes(x)) && 
                idListOfSorted.length == idListOfOriginal.length;
            
            if (sortingSuccessful) {
                console.log("%c Sorting OK.", "color: lightgreen");
                this.pouchReadErrorData = this.sortedData;
            } else {
                this.showErrorAndPartlySortedList(idListOfOriginal, idListOfSorted);
            }
        },

        // Categorized list for sorting the errors. 
        // The final list has multiple layers for sorting.
        buildCategorizedList() {
            const tempList = {
                New: [],
                Active: [],
                ReadError: [],
                ManualModeAvailableWithoutFixingTime: [],
                ManualModeAvailableWithFixingTime: [],
                LockedBy: []
            };

            const tempListSubCategories = {
                New: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
                Active: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
                ReadError: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
                ManualModeAvailableWithoutFixingTime: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
                ManualModeAvailableWithFixingTime: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
                LockedBy: {
                    AssistedOrTravel: [],
                    Normal: [],
                    DispenserOrClientDemoMode: []
                },
            };

            // Push errors into their corresponding sublists
            tempList.New = this.pouchReadErrorData.filter(entry => {
                return entry.data.state == "New";
            });

            tempList.Active = this.pouchReadErrorData.filter(entry => {
                return entry.data.state == "Active";
            });

            tempList.ReadError = this.pouchReadErrorData.filter(entry => {
                return !entry.data.state && !entry.data.version
            });

            tempList.ManualModeAvailableWithoutFixingTime = this.pouchReadErrorData.filter(entry => {
                return !entry.data.state.includes("Locked by") && entry.data.isManualModePouchReadError && !entry.data.lastTimeToStartFixing;
            });

            tempList.ManualModeAvailableWithFixingTime = this.pouchReadErrorData.filter(entry => {
                return !entry.data.state.includes("Locked by") && entry.data.isManualModePouchReadError && entry.data.lastTimeToStartFixing;
            });
            
            tempList.LockedBy = this.pouchReadErrorData.filter(entry => {
                return entry.data.state.includes("Locked by");
            });

            tempListSubCategories.New = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.New),
                Normal: this.filterNormal(tempList.New),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.New)
            };

            tempListSubCategories.Active = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.Active),
                Normal: this.filterNormal(tempList.Active),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.Active)
            };

            tempListSubCategories.ReadError = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.ReadError),
                Normal: this.filterNormal(tempList.ReadError),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.ReadError)
            };

            tempListSubCategories.ManualModeAvailableWithoutFixingTime = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.ManualModeAvailableWithoutFixingTime),
                Normal: this.filterNormal(tempList.ManualModeAvailableWithoutFixingTime),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.ManualModeAvailableWithoutFixingTime)
            };

            tempListSubCategories.ManualModeAvailableWithFixingTime = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.ManualModeAvailableWithFixingTime),
                Normal: this.filterNormal(tempList.ManualModeAvailableWithFixingTime),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.ManualModeAvailableWithFixingTime)
            };
            
            tempListSubCategories.LockedBy = {
                AssistedOrTravel: this.filterAssistedOrTravel(tempList.LockedBy),
                Normal: this.filterNormal(tempList.LockedBy),
                DispenserOrClientDemoMode: this.filterDispenserOrClientDemoMode(tempList.LockedBy)
            };

            let result = {
                New: [],
                Active: [],
                ReadError: [],
                ManualModeAvailableWithoutFixingTime: [],
                ManualModeAvailableWithFixingTime: [],
                LockedBy: []
            };

            /**
             * - key = New, Active, Locked by, ...
             * - subKey = AssistedOrTravel, Normal, DispenserOrClientDemoMode
             */
            for (const [key, list] of Object.entries(tempListSubCategories)) {
                for (const [subkey, sublist] of Object.entries(list)) {
                    sublist.sort(this.sortSubListByDates());
                    result[key].push(...sublist)
                }

                for(const row of result[key]) {
                    const [hours, minutes] = this.calculateNumericTimeLeftToFix(row.data.lastTimeToStartFixing);
                    // All errors with less than 15min highlighted as red, less than 30min left as yellow
                    // => hours can be negative if the time has already passed
                    if (hours <= 0) { 
                        if (minutes < 15) { 
                            row.rowClassName = "dangerRow";
                        }
                        else if (minutes < 30) {
                            row.rowClassName = "warningRow";
                        }
                    }
                    
                    // Travel modes highlighted as yellow, unless it already has the 'dangerRow' class (red borders)
                    if (this.isTravelMode(row.data.travelMode) && row.rowClassName !== "dangerRow") {
                        row.rowClassName = "warningRow";
                    }
                }                
            }

            return result;
        },

        filterNormal(list) {
            return list.filter(entry =>
                !entry.data.isDispenserDemoMode && 
                !entry.data.isAssistedMedicineDispensionEnabled && 
                !this.isTravelMode(entry.data.travelMode) && 
                !entry.data.isPatientDemoMode);
        },

        filterDispenserOrClientDemoMode(list) {
            return list.filter(entry =>
                (entry.data.isDispenserDemoMode || entry.data.isPatientDemoMode) &&
                !entry.data.isAssistedMedicineDispensionEnabled && 
                !this.isTravelMode(entry.data.travelMode)
            );
        },

        filterAssistedOrTravel(list) {
            return list.filter(entry => 
                entry.data.isAssistedMedicineDispensionEnabled || this.isTravelMode(entry.data.travelMode));
        },

        // Check for errors on any local
        checkForErrors(data) {           
            // Top level (not local specific) errors. Can also be a warning message from API about response containing fake data
            for (const desc of data.errorDescriptions) {
                this.errorMessage += `${desc}\n`
            }
            if (data.errorsExist == true) {
                // Errors specific for each local
                for (const local of data.result) {
                    if (local.errorsExist == true) {
                        this.errorMessage += `Error on ${local.localServerName}\n`;
                        for (const desc of local.errorDescriptions) {
                            this.errorMessage += `${desc}\n`
                        }
                        this.errorMessage += "\n";
                    }
                }
            }
        },

        checkLocalPermissions(data) {
            const locals = data.result;
            if (locals) {
                this.forbiddenLocalsList = "";
                for (const local of locals) {
                    if (!local.userLinkedToThisLocal) {
                        if (this.forbiddenLocalsList == "") {
                            this.forbiddenLocalsList += `${local.localServerName}`;
                        } else if (this.forbiddenLocalsList.length > 0) {
                            this.forbiddenLocalsList += `, ${local.localServerName}`;
                        }
                    }
                }
            }
        },

        calculateTimeLeftToFix(lastTimeToStartFixing) {
            if (lastTimeToStartFixing) {
                const [hours, minutes] = this.calculateNumericTimeLeftToFix(lastTimeToStartFixing);
                if (hours >= 0 && minutes >= 0) {
                    return `${hours}h ${minutes}m`;
                }
                return "0h 0m";
            }
            return "-";
        },

        calculateNumericTimeLeftToFix(lastTimeToStartFixing) {
            const difference = moment.duration(moment(lastTimeToStartFixing).diff(moment.utc()));
            return [difference.hours(), difference.minutes()];
        },

		formatTimeReceivedDate(date) {
            if (date == null) {
                return "-";
            }
			return moment(date).format(constants.timeReceivedTimeFormat);
		},

        showErrorAndPartlySortedList(idListOfOriginal, idListOfSorted) {
            const errorMsg = `Error occurred in sorting! Amount of read errors from API was ${this.pouchReadErrorData.length}. Sorted list had ${this.sortedData.length} read errors.`;
            console.warn(errorMsg);
            const idsNotInSortedList = idListOfOriginal.filter(id => !idListOfSorted.includes(id));
            if (idsNotInSortedList != null && idsNotInSortedList.length > 0) {
                console.warn("IDs not included in the sorted list: \n" + idsNotInSortedList + "\nCorresponding objects below:");
                this.showListItemDetails(idsNotInSortedList);
            }
            
            const duplicatesList = idListOfSorted.filter((item, index) => idListOfSorted.indexOf(item) !== index);
            if (duplicatesList != null && duplicatesList.length > 0) {
                console.warn("Duplicates:\n" + duplicatesList + "\nCorresponding objects below:");
                this.showListItemDetails(duplicatesList);
            }

            console.warn("Sorted list:\n" + idListOfSorted);
            console.warn("Original from API:\n" + idListOfOriginal);
            
            this.errorMessage += `${errorMsg}\n`;

            // Each line with a sorting error is marked (non-categorized or duplicated)
            // Non-categorized errors are before the sorted ones
            const nonCategorizedErrors = this.pouchReadErrorData.filter(x => idsNotInSortedList.includes(x.data.id));
            nonCategorizedErrors.forEach(item => {
                item.couldNotSortItem = true;
            });
            
            // Duplicates are shown in the list as sorted
            this.pouchReadErrorData.filter(x => duplicatesList.includes(x.data.id)).forEach(item => {
                item.couldNotSortItem = true;
            });
            const partlySortedList = nonCategorizedErrors;
            partlySortedList.push(...this.sortedData);
            this.pouchReadErrorData = partlySortedList;
        },

        showListItemDetails(listOfIds) {
            const detailedListItems = this.pouchReadErrorData.filter(x => listOfIds.includes(x.data.id)).map(x => {
                return x.data
            });
            console.warn(detailedListItems);
        },

        isTravelMode(travelModeType) {
            if (travelModeType == "Travel" || travelModeType == "Assisted") {
                return true;
            }
            return false;
        },

        notifyOnBrowserTab() {
            this.setNumberOfRefillActives();
            if (this.notifyConfig.notificationInterval == null) {
                this.notifyConfig.notificationInterval = setInterval(()=>{
                    if (document.title == constants.pageTitle && this.notifyConfig.countOfActiveRefills > 0) {
                        document.title = `(${this.notifyConfig.countOfActiveRefills}) ${constants.notificationTitle}`;
                    }
                    else {
                        document.title = constants.pageTitle;
                    }
                }, this.notifyConfig.blinkFreqInMs);
            }
        },

        cancelNotifyOnBrowserTab() {
            clearInterval(this.notifyConfig.notificationInterval);
            this.notifyConfig.notificationInterval = null;
            document.title = constants.pageTitle;
        },

        setNumberOfRefillActives() {
            const refillActives = this.pouchReadErrorData.filter(x => x.data.state == "Active");
            this.notifyConfig.countOfActiveRefills = refillActives.length;
        },

        onViewButtonClick(index) {
            this.pouchReadErrorData[index].rowClicked = true;

            // rowClicked set back to 'false' after the timeout period
            setTimeout(() => {
                this.pouchReadErrorData[index].rowClicked = false;
            }, this.viewButtonDisabledTimeInMs);
        }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import '~tc2.common.ui/dist/index.scss';

    // Alerts
    $refillActiveAlert: rgb(211, 44, 44);
    $urgentManualModeAlert: rgb(255, 182, 39);
    $alertBorderWidth: 8px;

    // Tags
    $demoModeAccent: rgb(102, 240, 102);
    $assistedModeAccent: rgb(182, 202, 255);
    $travelModeAccent: rgb(248, 213, 116);
    $demoClientAccent: white;

    // Table
    $tableBorderColor: rgb(212, 216, 219);
    $rowHoverColor: rgba(255, 255, 255, .75);
    $borderRadius: 7px;

    .table-hover {
        border-color: $tableBorderColor;
        & > tbody > tr {
            border-radius: $borderRadius;
            & > td {
                padding: 15px 9px;
            }
            &:last-of-type {
                border-bottom: white;
            }
            &:hover {
                background-color: $rowHoverColor;
            }
        }
    }
	.table-container {
		display: flex;
		flex-direction: column;
        min-height: 75vh;
	}
    pre {
        font-family: unset;
        white-space: pre-wrap;
    }

    .errorRow {
        border-left: $alertBorderWidth solid white;
        border-right: $alertBorderWidth solid white;
    }

    .dangerRow {
        border-left: $alertBorderWidth solid $refillActiveAlert;
        border-right: $alertBorderWidth solid $refillActiveAlert;
    }
    .warningRow {
        border-left: $alertBorderWidth solid $urgentManualModeAlert;
        border-right: $alertBorderWidth solid $urgentManualModeAlert;
    }

    .errorTag {
        display: block;
        margin-bottom: 1px;
        padding: 4px 7px;
        width: fit-content;
        border-radius: $borderRadius;
    }
    .demoModeTag {
        background-color: $demoModeAccent;
    }

    .assistedModeTag {
        background-color: $assistedModeAccent;
    }

    .travelModeTag {
        background-color: $travelModeAccent;
    }

    .demoClientTag {
        background-color: $demoClientAccent;
        box-shadow: inset 0px 0px 0px 0.6px black;
    }

    .viewButtonTd {
        vertical-align: middle;
        padding: 0 !important;
        fill: $primary-color
    }

    .disabledRow {
        opacity: .4;
        & > .viewButtonTd {
            cursor: not-allowed !important;
            & > a {
                pointer-events: none;
            }
        }
    }
</style>