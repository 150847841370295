<template>
    <div>
        <Navbar :user="user" @signout="logout" />
        <div class="container-fluid">
            <h3 v-if="title" class="mb-4 purpleText">{{$t(title)}}</h3>
            <button 
                v-if="env=='development'" 
                :class="isdemoDataMode == true ? 'btn-secondary' : 'purpleButton'" 
                class="btn ms-auto w-25" 
                @click="isdemoDataMode = !isdemoDataMode">
                    Toggle demo data
            </button>
            <h6 v-if="listUpdatedTime" class="lastUpdatedText">
                {{ $t("Last updated") }}: {{listUpdatedTime}}
            </h6>
            <Table ref="dataTable" />
        </div>
    </div>
</template>

<script>

import common from "tc2.common.ui";
import moment from "moment";
import Navbar from "../components/Navbar.vue";
import Table from "../components/Table.vue";
import constants from "../assets/constants.js";

export default {
    name: "App",
    components: {
        Navbar,
        Table
    },

    data: () => ({
        user: null,
        accessToken: null,
        idToken: null,
        sessionState: null,
        title: constants.pageTitle,
        listUpdatedTime: null,
        polling: null,
        dataRefreshInterval: process.env.VUE_APP_DATA_REFRESH_INTERVAL,
        isdemoDataMode: false,
        env: process.env.NODE_ENV
    }),
    beforeMount() {
        // Pressing F5 won't reload the whole page but fetches the data
        document.addEventListener("keydown", async (e) => {
            if (e.key == "F5" && !e.repeat) {
                e.preventDefault();
                await this.getData();
            }
        });
    },
    async mounted() {
        common.configureUserManager();
        const data = await common.checkIsUserLoggedIn();
        if (data != null && data.result != null) {
            this.user = data.result.profile;
            this.accessToken = data.result.access_token;
            this.idToken = data.result.id_token;
            this.sessionState = data.result.session_state;
            await this.getData();
            this.startInterval();
        } else if (data == null ) {
            clearInterval(this.polling);
        }
        else if (data == false) {
            this.$refs.dataTable.errorMessage += constants.loginAppErrorMsg;
            this.$refs.dataTable.loading = false;
        }
        // Listen for silent session refresh events and update access token
        common.startSessionListeners();
        document.addEventListener("silentRefresh", (res) => {
            if (res && res.detail && res.detail.access_token) {
                this.accessToken = res.detail.access_token;
            }
        });
    },

    watch: {
        isdemoDataMode: function (newValue) {
            this.getData();
        }
    },

    beforeDestroy () {
        clearInterval(this.polling);
    },

    methods: {
        startInterval() {
            this.polling = setInterval(async () => {
                common.checkSessionStatus();
                await this.getData();
            }, this.dataRefreshInterval);
        },

        async getData() {
            const wasFetchSuccessful = await this.$refs.dataTable.getPouchReadErrorData(this.accessToken, this.isdemoDataMode);
            if (wasFetchSuccessful) {
                this.listUpdatedTime = moment(new Date(Date.now())).format(constants.lastUpdatedTimeFormat);
            } else {
                this.listUpdatedTime = constants.listNotUpdatedErrorMsg
            }
        },

        logout() {
            common.logout(this.idToken);
        }
    }
};
</script>

<style>
.container-fluid {
    max-width: 1100px !important;
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
}

.lastUpdatedText {
     margin-left: auto; 
     margin-right:0;
}
</style>
