import moment from "moment";

const dateNow = Date.now();

export default {
    buildDemoData: () => {
            /**
             * NOTE!
             * - ASSISTED MODE AND TRAVEL MODE CANNOT BE BOTH ENABLED AT THE SAME TIME
             * - ASSISTED/TRAVEL CAN ONLY OCCUR IN MANUAL MODE (with/without time or locked by, pouchReadErrorTypeValue = 1 OR 2)
             * - all other combinations (demo mode/demo patient/assisted/travel) are possible
             */
            return [
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "TT100042",
                        id: 100,
                        isDispenserDemoMode: true,
                        isPatientDemoMode: true,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-b.evondos.fi/Client/Details/48104",
                        patientId: 48104,
                        patientState: 1,
                        pouchReadErrorTypeValue: 3,
                        readErrorDetailsUrl: "https://d1-anna-b.evondos.fi/RefillReadError/Details/36",
                        state: "Active",
                        timeReceived: "2021-06-27T10:10:45.7424374",
                        version: "R1"
                    }
                },
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "RR005",
                        id: 8,
                        isDispenserDemoMode: true,
                        isPatientDemoMode: true,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: true,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: moment(dateNow).add(15, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2021-06-23T09:34:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "RR666",
                        id: 77,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: true,
                        travelMode: "Unknown",
                        lastTimeToStartFixing: moment(dateNow).add(11, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2021-06-22T09:34:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "LocalB",
                    "data": {
                        dispenserSerialNumber: "TT100456",
                        id: 44,
                        isManualModePouchReadError: false,
                        isDispenserDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isPatientDemoMode: false,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-b.evondos.fi/Client/Details/48104",
                        patientId: 48104,
                        patientState: 1,
                        pouchReadErrorTypeValue: 3,
                        readErrorDetailsUrl: "https://d1-anna-b.evondos.fi/RefillReadError/Details/36",
                        state: "Active",
                        timeReceived: "2021-06-26T10:10:45.7424374",
                        version: "R1"
                    }
                },
                {
                    "localServerName": "FIN/NOR",
                    "data": {
                        dispenserSerialNumber: "RR009",
                        id: 11,
                        isDispenserDemoMode: true,
                        isPatientDemoMode: true,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        lastTimeToStartFixing: null,
                        travelMode: "NoTravel",
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2021-06-21T09:34:03.1323996",
                        version: ""
                    }
                },
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "RR013",
                        id: 59,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: true,
                        travelMode: "Travel",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 2,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2021-06-30T09:34:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "RR014",
                        id: 60,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: true,
                        travelMode: "Assisted",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 2,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2022-06-30T09:34:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "SWE",
                    "data": {
                        dispenserSerialNumber: "RR0099",
                        id: 203,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "Unknown",
                        lastTimeToStartFixing: moment(dateNow).add(2, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "Locked by X",
                        timeReceived: "2021-06-27T06:22:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "LocalA",
                    "data": {
                        dispenserSerialNumber: "TT13352",
                        id: 36,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-b.evondos.fi/Client/Details/48104",
                        patientId: 48104,
                        patientState: 1,
                        pouchReadErrorTypeValue: 3,
                        readErrorDetailsUrl: "https://d1-anna-b.evondos.fi/RefillReadError/Details/36",
                        state: "Active",
                        timeReceived: "2021-06-28T10:10:45.7424374",
                        version: "R1"
                    }
                },
                {
                    "localServerName": "LocalB",
                    "data": {
                        dispenserSerialNumber: "RR092",
                        id: 139,
                        isDispenserDemoMode: true,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: moment(dateNow).add(103, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "Locked by Y",
                        timeReceived: "2021-06-26T06:22:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "FIN/NOR",
                    "data": {
                        dispenserSerialNumber: "RR026",
                        id: 1,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: null,
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "",
                        timeReceived: "2021-06-23T09:34:03.1323996",
                        version: ""
                    }
                },
                {
                    "localServerName": "FIN/NOR",
                    "data": {
                        dispenserSerialNumber: "RR5",
                        id: 288,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: false,
                        isManualModePouchReadError: false,
                        travelMode: "Unknown",
                        lastTimeToStartFixing: moment(dateNow).add(30, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 1,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "Locked by Z",
                        timeReceived: "2021-06-30T06:22:03.1323996",
                        version: "2"
                    }
                },
                {
                    "localServerName": "FIN/NOR",
                    "data": {
                        dispenserSerialNumber: "RR9",
                        id: 291,
                        isDispenserDemoMode: false,
                        isPatientDemoMode: false,
                        isAssistedMedicineDispensionEnabled: true,
                        isManualModePouchReadError: true,
                        travelMode: "NoTravel",
                        lastTimeToStartFixing: moment(dateNow).add(20, "minutes"),
                        patientDetailsUrl: "https://d1-anna-a.evondos.fi/Client/Details/41053",
                        patientId: 41053,
                        patientState: 1,
                        pouchReadErrorTypeValue: 2,
                        readErrorDetailsUrl: "https://d1-anna-a.evondos.fi/PouchImageReadError/Details/1369",
                        state: "Locked by A",
                        timeReceived: "2021-06-26T06:22:03.1323996",
                        version: "2"
                    }
                }
            ]
        }
}